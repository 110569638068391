import FileDownload from 'js-file-download';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { parse } from 'date-fns';

import apiAction from 'actions/_shared/apiAction';
import { makeApiUrl } from 'actions/_shared/utils';
import { authService } from 'services/AuthProvider';
import {
  SystemPageCardResponseType,
  ColorDataItem,
  UniversalSystemPageRequestType,
  UniversalSystemPageMesage,
  DayViewType,
} from 'store/storeTypes';
import { mapResposeToTimeLineDayArray } from 'helpers/timelineUtils';
import { SetTimeLineHour, ToggleHour } from 'components/SystemPage/TimeLineChart/TimePicker';
import { getRealTimeHeaders } from './_shared/realTimeHeaders';

export const toggleAMPMmodificator = createStandardAction('TOGGLE_AM_PM')<ToggleHour>();
export const setTimeLineHour = createStandardAction('SET_TIMELINE_HOUR')<SetTimeLineHour>();
export const incrementTimeLineHour = createStandardAction('INCREMENT_HOUR_TIMELINE')();
export const decrementTimeLineHour = createStandardAction('DECREMENT_HOUR_TIMELINE')();
export const toggleDayTimelineView = createStandardAction('TOGGLE_DAY_TIMELINE_VIEW')();

export const downloadErrorAndMaintenanceReportAC = createAsyncAction(
  'DOWNLOAD_REPORT_STARTED',
  'DOWNLOAD_REPORT_SUCCESS',
  'DOWNLOAD_REPORT_FAILED'
)<GetDataRequest, any, Error>();

export const downloadErrorAndMaintenanceReport = ({ day, week, systemGroup, system }: any) => {
  const url = makeApiUrl('/api/system-page-report/error-maintenance-file');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    return apiAction({
      request: {
        url,
        params: {
          day,
          week,
          systemGroup,
          system,
          responseType: 'blob',
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(
            downloadErrorAndMaintenanceReportAC.failure({
              name: error.name,
              message: error.message,
            })
          );
        },
        onStarted: dispatch => {
          dispatch(downloadErrorAndMaintenanceReportAC.request({ day, week, systemGroup, system }));
        },
        onSuccess: (data, dispatch) => {
          //*here we need to check if system field is array because in some cases we send this request with single string and in this case we need to handle response in one way and if it is array we need to handle it in another way
          if (Array.isArray(system)) {
            FileDownload(data, `${'Events_End_Errors_Report'}.xlsx`);
            dispatch(downloadErrorAndMaintenanceReportAC.success(data));
          } else {
            const nameArray = system.split(' ');
            const serialNumber = nameArray[nameArray.length - 1];
            const dateSuffix = week ? week : day;
            const name = `${serialNumber}_Events_${dateSuffix}`;
            FileDownload(data, `${name}.xlsx`);
            dispatch(downloadErrorAndMaintenanceReportAC.success(data));
          }
        },
      },
    });
  }
};

export type GetDataRequest = any;
export type DayObject = {
  date: Date;
  stringDate: string;
};

export const getTimelineDayViewRequestAC = createAsyncAction(
  'GET_TIMELINE_DATA_DAY_VIEW_STARTED',
  'GET_TIMELINE_DATA_DAY_VIEW_SUCCESS',
  'GET_TIMELINE_DATA_DAY_VIEW_FAILED'
)<GetDataRequest, Object, Error>();
export const getTimelineDayViewRequest = ({ day, hour, systemGroup, system }: GetDataRequest) => {
  const url = makeApiUrl('/api/system-page-report/system-timeline-axis');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload?.sub) {
    return apiAction({
      request: {
        url,
        params: {
          day,
          hour,
          systemGroup,
          system,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(getTimelineDayViewRequestAC.failure(error));
        },
        onStarted: dispatch => {
          dispatch(getTimelineDayViewRequestAC.request({ day, hour, systemGroup, system }));
        },
        onSuccess: (data, dispatch) => {
          //*day request
          const { timeLineData } = data;
          //*if length 288 - it is 24 hour view request if 60 - hour view
          const type = timeLineData.length === 288 ? DayViewType.day : DayViewType.hour;
          const result = mapResposeToTimeLineDayArray(timeLineData, type);
          dispatch(getTimelineDayViewRequestAC.success(result));
        },
      },
    });
  }
};

export const getTimeLineDataAC = createAsyncAction(
  'GET_TIMELINE_DATA_WEEK_VIEW_STARTED',
  'GET_TIMELINE_DATA_WEEK_VIEW_SUCCESS',
  'GET_TIMELINE_DATA_WEEK_VIEW_FAILED'
)<any, any, Error>();
export const getTimeLineDataRequest = ({ day, week, systemGroup, system }: GetDataRequest) => {
  const url = makeApiUrl('/api/system-page-report/system-timeline');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    return apiAction({
      request: {
        url,
        params: {
          day,
          week,
          systemGroup,
          system,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(getTimeLineDataAC.failure({ name: error.name, message: error.message }));
        },
        onStarted: dispatch => {
          dispatch(getTimeLineDataAC.request({ day, week, systemGroup, system }));
        },
        onSuccess: (data, dispatch) => {
          //*week req
          const {
            resultSummaryDataArray,
            resultTimeLineDataArray,
          } = mapTimeLineResponeToResultSortedObject(data, week);
          dispatch(getTimeLineDataAC.success({ resultSummaryDataArray, resultTimeLineDataArray }));
        },
      },
    });
  }
};
const sortArrayByDay = arr => {
  return arr.sort((a, b) => {
    const dateA = parse(a.day, 'yyyyMMdd', new Date()).getTime();
    const dateB = parse(b.day, 'yyyyMMdd', new Date()).getTime();

    return dateA - dateB;
  });
};

export function mapTimeLineResponeToResultSortedObject(data, week) {
  const isWeek = week ? true : false;
  const { systemTimeLineGraphResponse, systemTimeLineSummeryResponse } = data;
  const { systemTimeLineGraphData: dataArray } = systemTimeLineGraphResponse;
  const { systemTimeLineSummeryData: dataSummaryArray } = systemTimeLineSummeryResponse;
  const resultSummaryDataArray = new Array();
  const resultTimeLineDataArray = new Array();

  dataSummaryArray.forEach(element => {
    const statusName = element.status;
    const position = timeLineSortingObject.find(el => el.name === statusName).positionInOrder - 1;
    resultSummaryDataArray.splice(position, 0, element);
  });
  if (isWeek) {
    const sortedArray = sortArrayByDay(dataArray);
    const firstDay: string = sortedArray[0].day;
    const { startYear, startMonth, startDay, startDate } = mapStringToDate(firstDay);
    const allWeekDaysStringArray = getAllWeekDaysObjects(startDate, firstDay);
    allWeekDaysStringArray.forEach((dayObject: DayObject) => {
      const filteredByDate = sortedArray.filter(el => el.day === dayObject.stringDate);
      const result = {
        date: dayObject.date,
        stringDate: dayObject.stringDate,
        Error:
          (filteredByDate.find(el => el.status === 'Error') &&
            filteredByDate.find(el => el.status === 'Error').statusDurationPercentage) ||
          0,
        Maintenance:
          (filteredByDate.find(el => el.status === 'Maintenance') &&
            filteredByDate.find(el => el.status === 'Maintenance').statusDurationPercentage) ||
          0,
        Idle:
          (filteredByDate.find(el => el.status === 'Idle') &&
            filteredByDate.find(el => el.status === 'Idle').statusDurationPercentage) ||
          0,
        Printing:
          (filteredByDate.find(el => el.status === 'Printing') &&
            filteredByDate.find(el => el.status === 'Printing').statusDurationPercentage) ||
          0,
        NotReporting:
          (filteredByDate.find(el => el.status === 'Not Reporting') &&
            filteredByDate.find(el => el.status === 'Not Reporting').statusDurationPercentage) ||
          0,
      };
      resultTimeLineDataArray.push(result);
    });
  }
  return { resultSummaryDataArray, resultTimeLineDataArray };
}
export function getAllWeekDaysObjects(startDate: Date, firstDay: string) {
  const resultArray = new Array();
  resultArray.push({ date: startDate, stringDate: firstDay });
  for (let i = 1; i <= 6; i++) {
    const year = startDate.getFullYear();
    const month = startDate.getMonth();
    const day =
      startDate.getDate() + i < 10 ? `0${startDate.getDate() + i}` : `${startDate.getDate() + i}`;

    const nextDate = new Date(year, month, +day);
    const nextMonth = nextDate.getMonth() + 1;
    const monthString = nextMonth < 10 ? `0${nextMonth}` : nextMonth;
    const nextDay = nextDate.getDate();
    const nextDayString = nextDay < 10 ? `0${nextDay}` : nextDay;
    resultArray.push({
      date: nextDate,
      stringDate: `${nextDate.getFullYear()}${monthString}${nextDayString}`,
    });
  }
  return resultArray;
}
export function mapStringToDate(date: string) {
  const splittedDate = date.split('');
  const startYear = Number(splittedDate.slice(0, 4).join(''));
  const startMonth = Number(splittedDate.slice(4, 6).join(''));
  const startDay = Number(splittedDate.slice(6, splittedDate.length).join(''));
  const startDate = new Date(startYear, startMonth - 1, startDay);
  return { startYear, startMonth, startDay, startDate };
}
export const getSystemPageCardsDataAC = createAsyncAction(
  'GET_SYSTEM_PAGE_CARDS_STARTED',
  'GET_SYSTEM_PAGE_CARDS_SUCCESS',
  'GET_SYSTEM_PAGE_CARDS_FAILED'
)<UniversalSystemPageRequestType, SystemPageCardResponseType, Error>();

export const getSystemPageCardsDataRequest = ({
  day,
  week,
  systemGroup,
  system,
}: UniversalSystemPageRequestType) => {
  const url = makeApiUrl('/api/system-page-report/cards');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    return apiAction({
      request: {
        url,
        params: {
          day,
          week,
          systemGroup,
          system,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(getSystemPageCardsDataAC.failure({ name: error.name, message: error.message }));
        },
        onStarted: dispatch => {
          dispatch(getSystemPageCardsDataAC.request({ day, week, systemGroup, system }));
        },
        onSuccess: (data, dispatch) => {
          const {
            achievement,
            productionRateChangeLastWeek,
            productionRateChangeFleet,
            productionRate,
            productionChangeLastWeek,
            productionChangeFleet,
            production,
            printerImage,
            lastStatus,
            lastSoftwareUpdate,
            availabilityChangeLastWeek,
            availabilityChangeFleet,
            availability,
            appVersion,
            errorDuration,
            errorPercentage,
            maintenanceDuration,
            maintenancePercentage,
            productivity,
            productivityChangeFleet,
            productivityChangeLastWeek,
            isNotReportingPeriod,
            availabilityTotalDurationReported,
            systemModel,
          } = data;
          dispatch(
            getSystemPageCardsDataAC.success({
              achievement,
              productionRateChangeLastWeek,
              productionRateChangeFleet,
              productionRate,
              productionChangeLastWeek,
              productionChangeFleet,
              production,
              printerImage,
              lastStatus,
              lastSoftwareUpdate,
              availabilityChangeLastWeek,
              availabilityChangeFleet,
              availability,
              appVersion,
              errorDuration,
              errorPercentage,
              maintenanceDuration,
              maintenancePercentage,
              productivity,
              productivityChangeFleet,
              productivityChangeLastWeek,
              isNotReportingPeriod,
              availabilityTotalDurationReported,
              systemModel,
            })
          );
        },
      },
    });
  }
};
export type SystemPageMessagesResponseType = {
  maintenanceTableData: {
    messageTableData: Array<UniversalSystemPageMesage>;
  };
  errorTableData: {
    messageTableData: Array<UniversalSystemPageMesage>;
  };
};
export const getSystemPageMessagesAC = createAsyncAction(
  'GET_SYSTEM_PAGE_MESSAGES_STARTED',
  'GET_SYSTEM_PAGE_MESSAGES_SUCCESS',
  'GET_SYSTEM_PAGE_MESSAGES_FAILED'
)<UniversalSystemPageRequestType, any, Error>();
export const getSystemPageMessagesRequest = (
  { day, week, systemGroup, system }: UniversalSystemPageRequestType,
  isSilent,
  isRealTime = false
) => {
  const url = makeApiUrl('/api/system-page-report/error-maintenance-messages');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub)
    return apiAction({
      request: {
        url,
        headers: getRealTimeHeaders(isRealTime),
        params: {
          day,
          week,
          systemGroup,
          system,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(getSystemPageMessagesAC.failure({ name: error.name, message: error.message }));
        },
        onStarted: dispatch => {
          dispatch(getSystemPageMessagesAC.request({ day, week, systemGroup, system, isSilent }));
        },
        onSuccess: (data, dispatch) => {
          dispatch(getSystemPageMessagesAC.success(data));
        },
      },
    });
};

export const getTotalBottlesLoadedAC = createAsyncAction(
  'GET_TOTAL_BOTTLES_LOADED_STARTED',
  'GET_TOTAL_BOTTLES_LOADED_SUCCESS',
  'GET_TOTAL_BOTTLES_LOADED_FAILED'
)<UniversalSystemPageRequestType, Array<ColorDataItem>, Error>();
export const totalBottlesLoadedDataRequest = (
  { day, week, systemGroup, system }: UniversalSystemPageRequestType,
  isSilent
) => {
  const url = makeApiUrl('/api/system-page-report/ink-table');
  const { isAuthenticated, idTokenPayload } = authService;
  const isToday = !!(day && isSilent);
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub)
    return apiAction({
      request: {
        url,
        headers: getRealTimeHeaders(isSilent),
        params: {
          day,
          week,
          systemGroup,
          system,
          isToday: isToday,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(getTotalBottlesLoadedAC.failure({ name: error.name, message: error.message }));
        },
        onStarted: dispatch => {
          dispatch(getTotalBottlesLoadedAC.request({ day, week, systemGroup, system, isSilent }));
        },
        onSuccess: (data, dispatch) => {
          const { colorData } = data;
          //*there is a bug on backend side - sometimes we got negative values for for colorValue - there is no possibility to fix it on back end site so we need to handle it here. bug number 138641
          //* also contract has changed now insead of numbers also possible to get 'Infinity' string - should be mapped to 0
          const normalizedData = setAllNegativeValuesOfArrayToZero(colorData);
          dispatch(getTotalBottlesLoadedAC.success(normalizedData));
        },
      },
    });
};

export const getQualisetDataAC = createAsyncAction(
  'GET_QUALISET_DATA_STARTED',
  'GET_QUALISET_DATA_SUCCESS',
  'GET_QUALISET_DATA_FAILED'
)<UniversalSystemPageRequestType, any, Error>();
export const getQualisetDataRequest = ({
  day,
  week,
  systemGroup,
  system,
}: UniversalSystemPageRequestType) => {
  const url = makeApiUrl('/api/system-page-report/qualiset');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub)
    return apiAction({
      request: {
        url,
        params: {
          day,
          week,
          systemGroup,
          system,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(getQualisetDataAC.failure(error));
        },
        onStarted: dispatch => {
          dispatch(getQualisetDataAC.request({ day, week, systemGroup, system }));
        },
        onSuccess: (data, dispatch) => {
          dispatch(getQualisetDataAC.success({ data, systemGroup, system }));
        },
      },
    });
};

export const getImpressionsCardsAC = createAsyncAction(
  'GET_IMPRESSIONS_CARDS_STARTED',
  'GET_IMPRESSIONS_CARDS_SUCCESS',
  'GET_IMPRESSIONS_CARDS_FAILED'
)<UniversalSystemPageRequestType, any, Error>();
export const getImressionCardsRequest = (
  { day, week, systemGroup, system, isSilent }: UniversalSystemPageRequestType,
  isRealTime: boolean = false
) => {
  const url = makeApiUrl('/api/system-page-report/impressions-cards');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub)
    return apiAction({
      request: {
        url,
        headers: getRealTimeHeaders(isRealTime),
        params: {
          day,
          week,
          systemGroup,
          system,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(getImpressionsCardsAC.failure(error));
        },
        onStarted: dispatch => {
          dispatch(getImpressionsCardsAC.request({ day, week, systemGroup, system, isSilent }));
        },
        onSuccess: (data, dispatch) => {
          dispatch(getImpressionsCardsAC.success({ data, systemGroup }));
        },
      },
    });
};

export const getAvailabilityCardAC = createAsyncAction(
  'GET_AVAILABILITY_CARD_STARTED',
  'GET_AVAILABILITY_CARD_SUCCESS',
  'GET_AVAILABILITY_CARD_FAILED'
)<UniversalSystemPageRequestType, any, Error>();
export const getAvailabilityCardRequest = (
  { day, week, systemGroup, system, timeLineGranularity, isSilent }: UniversalSystemPageRequestType,
  isRealTime: boolean = false
) => {
  const url = makeApiUrl('/api/system-page-report/availability-and-timeline');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub)
    return apiAction({
      request: {
        url,
        headers: getRealTimeHeaders(isRealTime),
        params: {
          day,
          week,
          systemGroup,
          system,
          timeLineGranularity,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(getAvailabilityCardAC.failure(error));
        },
        onStarted: dispatch => {
          dispatch(getAvailabilityCardAC.request({ day, week, systemGroup, system, isSilent }));
        },
        onSuccess: (data, dispatch) => {
          if (week) {
            const { systemPageAvailabilityCardResponse, systemTimeLineWeekResponse } = data;

            const {
              systemTimeLineGraphResponse,
              systemTimeLineSummeryResponse,
            } = systemTimeLineWeekResponse;

            const {
              resultSummaryDataArray,
              resultTimeLineDataArray,
            } = mapTimeLineResponeToResultSortedObject(
              { systemTimeLineGraphResponse, systemTimeLineSummeryResponse },
              week
            );
            dispatch(
              getAvailabilityCardAC.success({
                systemPageAvailabilityResponse: systemPageAvailabilityCardResponse,
                resultSummaryDataArray,
                resultTimeLineDataArray,
                systemGroup,
                day,
                week,
              })
            );
          }

          if (day) {
            const DAY_VIEW_RECORDS_COUNT = 288;
            const { timeLineData } = data.systemTimeLineDayResponse;
            const type =
              timeLineData.length === DAY_VIEW_RECORDS_COUNT ? DayViewType.day : DayViewType.hour;
            const result = mapResposeToTimeLineDayArray(timeLineData, type);
            dispatch(
              getAvailabilityCardAC.success({
                systemPageAvailabilityResponse: data.systemPageAvailabilityCardResponse,
                systemTimeLineDayResponse: result,
                systemGroup,
                day,
                week,
              })
            );
          }
        },
      },
    });
};
export const getAchievementCardAC = createAsyncAction(
  'GET_ACHIEVEMENT_CARD_STARTED',
  'GET_ACHIEVEMENT_CARD_SUCCESS',
  'GET_ACHIEVEMENT_CARD_FAILED'
)<UniversalSystemPageRequestType, any, Error>();
export const getAchievementCardRequest = (
  { day, week, systemGroup, system, timeLineGranularity, isSilent }: UniversalSystemPageRequestType,
  isRealTime: boolean = false
) => {
  //*only serial number needed - it is always last element in system string
  const serialNumber = system.split(' ').pop();
  const url = makeApiUrl('/api/system-page-report/achievement');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub)
    return apiAction({
      request: {
        url,
        headers: getRealTimeHeaders(isRealTime),
        params: {
          day,
          week,
          systemGroup,
          system: serialNumber,
          timeLineGranularity,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(getAchievementCardAC.failure(error));
        },
        onStarted: dispatch => {
          dispatch(getAchievementCardAC.request({ day, week, systemGroup, system, isSilent }));
        },
        onSuccess: (data, dispatch) => {
          dispatch(getAchievementCardAC.success(data));
        },
      },
    });
};
//*sorting model for timeline
export const timeLineSortingObject = [
  { name: 'Error', positionInOrder: 1, color: '#FF404F' },
  { name: 'Maintenance', positionInOrder: 2, color: '#FFAB2B' },
  { name: 'Idle', positionInOrder: 3, color: '#5E7EBF' },
  { name: 'Printing', positionInOrder: 4, color: '#1BB934' },
  { name: 'NotReporting', positionInOrder: 5, color: 'C5D0DE' },
  { name: 'Not Reporting', positionInOrder: 5, color: 'C5D0DE' },
];

const setAllNegativeValuesOfArrayToZero = (array: Array<{ colorName: string; colorValue }>) => {
  const result = array.map(el => {
    if (el.colorValue < 0) {
      return { ...el, colorValue: 0 };
    }
    if (el.colorValue === 'Infinity') {
      return { ...el, colorValue: 0 };
    } else {
      return el;
    }
  });
  return result;
};
