import { isAfter, parse } from 'date-fns';
import { globalColors, XMRColors } from 'globalStyle';
export const getColor = (value: number) => {
  if (value >= 0 && value <= 50) {
    return XMRColors.red;
  }
  if (value > 50 && value <= 75) {
    return XMRColors.yellow;
  } else {
    return XMRColors.green;
  }
};
export const getMaxValue = data => {
  let maxValue = -Infinity;
  data.forEach(record => {
    const avg = record.avg;
    const xValue = record.x.value;
    const minMax = Math.max(...record.minMax);
    const currentMaxValue = Math.max(maxValue, avg, xValue, minMax);
    maxValue = currentMaxValue > maxValue ? currentMaxValue : maxValue;
  });
  return maxValue;
};
//* Add to data gray zone based on currentShiftStartTime
export const addGrayZone = (data, currentShiftStartTime, maxValue) => {
  const timeLimit = new Date(currentShiftStartTime);
  //if in records there is record 0:00 it means there are 2 days in records
  const indexOfNewDay = data.findIndex(record => record.time === '0:00');
  const result = data.map((record, index) => {
    const recordTime = parse(record.time, 'HH:mm', new Date()); // parse each record to time
    // if there are 2 days in records than we have to substract 1 day from recordTime variable
    if (index < indexOfNewDay) {
      recordTime.setDate(recordTime.getDate() - 1);
    }
    if (isAfter(recordTime, timeLimit)) {
      return { ...record, grayZone: [0, 0] }; // add gray zone with : [0, 0]
    } else {
      return { ...record, grayZone: [0, maxValue] }; // add gray zone with :  [0, maxValue]
    }
  });
  return result;
};
export const convertServerResponseToChartData = data => {
  return data.map(record => {
    const date = new Date(record.intervalStart);
    const hours = date.getUTCHours();
    const minutes = ('0' + date.getUTCMinutes()).slice(-2);
    return {
      time: `${hours}:${minutes}`,

      avg: record.xAvg ?? 0,
      x: {
        value: record.x ?? 0,
        isPurple: !!record.xRule1,
      },
      minMax: [record.xMin ?? 0, record.xMax ?? 0],
      timeSpan: record.timeSpan ?? 0,
      currentImpression: record.currentImpression ?? 0,
      currentImpressionPerformance: record.currentImpressionPerformance ?? 0,
      imageName: record.imageName ?? '',
      imageCopiesPrinted: record.imageCopiesPrinted ?? 0,
      imagePrintingPerformance: record.imagePrintingPerformance ?? 0,
    };
  });
};
export const getFirstSystem = ({ filterData, selectedSystemGroup }) => {
  const firstSite = filterData[selectedSystemGroup].sites[0];
  const firstSiteSystems = filterData[selectedSystemGroup].systems
    .filter(system => system.siteId === firstSite.id)
    .sort((a, b) => a.name.localeCompare(b.name));
  return firstSiteSystems[0];
};
