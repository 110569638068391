import React from 'react';
import styled from 'styled-components';
import {
  BarChart,
  LabelProps,
  Tooltip,
  XAxis,
  Bar,
  LabelList,
  ResponsiveContainer,
  Label,
} from 'recharts';
import { globalColors } from '../../../globalStyle';
import { formatFloatToPercentage, numberFormatterNew } from 'helpers/conversions';
import { TimeFrame, TimeFrameEnum } from 'models/ReportData';

import {
  ActiveOverviewChart,
  ChartRecord,
  UserSystemGroup,
  UserSystemGroupStrings,
} from 'store/storeTypes';
import { TooltipText } from './SpeedChart';
import { Spinner } from '../Styles';
import { getBarSizeSpeedChart, getGraphMarginSpeedChart } from 'helpers/chartUtils';
import AxixTiksForCustomTimeframe from './AxixTiksForCustomTimeframe';
import { CustomTimeFrameProperties } from 'actions/reportFilterActions';
import { normalizeApolloAllChartsRecords } from 'helpers/overviewUtils';

const TooltipContainer = styled.div<{ height: number }>`
  display: grid;
  grid-template-rows: 2fr 1fr 3px 2fr 1fr;
  border: 1px solid #c5d0de;
  background: white;
  width: auto;
  height: ${props => props.height}px;
  border-radius: 4px;
  box-shadow: 2px 2px 5px #00000022;
  padding: 8px;
`;

const TooltipValue = styled.div`
  font-size: 14px;
  & span {
    font-size: 12px;
  }
`;
const TooltipTitle = styled.div`
  font-size: 10px;
`;
const TooltipDivider = styled.div`
  border-bottom: 1px solid #c5d0de;
  width: 100%;
  margin-bottom: 5px;
`;
const CustomTooltip = props => {
  const { units, active, payload: propPayload, tooltipText, chartName, timeframe } = props;
  const jobStartedPayload = propPayload[0]?.payload;
  const isUtilization = () => units === '%';
  if (active && propPayload && propPayload[0]) {
    const payload = propPayload[0].payload;
    const getTooltipHeight = (chartName: ActiveOverviewChart, timeframe: TimeFrame): number => {
      if (chartName === ActiveOverviewChart.Speed) {
        return timeframe === TimeFrameEnum.day ? 130 : 85;
      }
      if (chartName === ActiveOverviewChart.Utilization) {
        return 85;
      }
    };
    //*this container has different height for speed chart and utilization chart. also there is a difference in height in day timeframe and week timeframe
    const containerHeight = getTooltipHeight(chartName, timeframe);
    return (
      <TooltipContainer
        height={containerHeight}
        data-automationid="Overview-Total-impressions-by-hour-tooltip">
        <TooltipValue data-automationid="Overview-Total-impressions-by-hour-tooltip-upper-value">
          {isUtilization()
            ? formatFloatToPercentage(payload.total)
            : numberFormatterNew(payload.total)}{' '}
          <span>{units}</span>
          {chartName === ActiveOverviewChart.Speed && timeframe === TimeFrameEnum.day && (
            <>
              <TooltipValue>
                {jobStartedPayload.toolTip ? jobStartedPayload.toolTip.toFixed(0) : '0'}
                <span> Job Started</span>
              </TooltipValue>
            </>
          )}
        </TooltipValue>

        <TooltipTitle>{tooltipText.total}</TooltipTitle>

        <TooltipDivider></TooltipDivider>
        <TooltipValue data-automationid="Overview-Total-impressions-by-hour-tooltip-bottom-value">
          {isUtilization()
            ? formatFloatToPercentage(payload.totalAvg)
            : numberFormatterNew(payload.totalAvg)}{' '}
          <span>{units}</span>
          {chartName === ActiveOverviewChart.Speed && timeframe === TimeFrameEnum.day && (
            <>
              <TooltipValue>
                {jobStartedPayload.toolTipLastWeek
                  ? jobStartedPayload.toolTipLastWeek.toFixed(0)
                  : '0'}
                <span> Job Started</span>
              </TooltipValue>
            </>
          )}
        </TooltipValue>

        <TooltipTitle>{tooltipText.totalAvg}</TooltipTitle>
      </TooltipContainer>
    );
  }

  return null;
};

const ChartLabel = (
  props: LabelProps & { units?: string } & { selectedSystemGroup?: string } & {
    timeframe: TimeFrame;
    columnWidth: number;
  }
) => {
  const { value, x, y, offset, units, selectedSystemGroup, timeframe, width, columnWidth } = props;

  let result;
  let unitsPostfix;
  if (selectedSystemGroup === 'DTF' && units === '%') {
    unitsPostfix = '%';
  }
  if (
    (!isNaN(value as number) && Math.floor(value as number) !== value) ||
    (!isNaN(value as number) && (value as number) === 1)
  ) {
    if (units === '%') {
      //*productivity by hour chart
      // result = formatFloatToPercentage(value as number);
      result = Math.round((value as number) * 100);
    } else {
      //*Total Impressions by Hour chart
      result = numberFormatterNew(value as number);
    }
  } else {
    result = value;
  }
  const xOffset = +x + +width / 2;
  const yOffset = y ? +y - 5 : -5;
  return (
    <g>
      <text
        textAnchor="middle"
        fontSize="10px"
        fontWeight="500"
        fill={globalColors.regular.text.primary}
        x={xOffset}
        y={yOffset}
        offset={offset}>
        {result}
        {!!unitsPostfix && unitsPostfix}
      </text>
    </g>
  );
};

const CustomizedLabelGrid = props => {
  //*this greed is needed only in case of week view
  const { timeframe, viewBox, offsetRight, offsetLeft, chartHeight } = props;

  const { width } = viewBox;
  const yOffSet = 50;
  const heigth = chartHeight - yOffSet;
  const LINES_COUNT = 5;
  const step = heigth / LINES_COUNT;
  const fullWidth = width + offsetLeft + offsetRight;
  return (
    <svg>
      <line
        x1="10"
        y1={heigth}
        x2={fullWidth}
        y2={heigth}
        stroke={globalColors.regular.background.greyDevider}
      />
      <line
        x1="10"
        y1={heigth - step}
        x2={fullWidth}
        y2={heigth - step}
        stroke={globalColors.regular.background.greyDevider}
      />
      <line
        x1="10"
        y1={heigth - step * 2}
        x2={fullWidth}
        y2={heigth - step * 2}
        stroke={globalColors.regular.background.greyDevider}
      />

      <line
        x1="10"
        y1={heigth - step * 3}
        x2={fullWidth}
        y2={heigth - step * 3}
        stroke={globalColors.regular.background.greyDevider}
      />
      <line
        x1="10"
        y1={heigth - step * 4}
        x2={fullWidth}
        y2={heigth - step * 4}
        stroke={globalColors.regular.background.greyDevider}
      />
    </svg>
  );
};
type DesktopSpeedChartProps = {
  data: ChartRecord[];
  timeframe: TimeFrame;
  selectedSystemGroup: UserSystemGroupStrings;
  tooltipText: TooltipText;
  units: string;
  isWeek: boolean;
  chartName: ActiveOverviewChart;
  screenWidth: number;
  customTimeframeProperties?: CustomTimeFrameProperties;
  maxKeepAlive?: Date | undefined;
};
const DesktopSpeedChart = (props: DesktopSpeedChartProps) => {
  const {
    data,
    timeframe,
    selectedSystemGroup,
    tooltipText,
    units,
    isWeek,
    chartName,
    screenWidth,
    customTimeframeProperties,
    maxKeepAlive,
  } = props;
  const barRadius = 8;

  const barSize = data.length && getBarSizeSpeedChart(screenWidth, data.length);
  const CHART_HEIGHT = 160;
  if (!data.length || timeframe === 'year' || timeframe === 'month' || timeframe === 'quarter') {
    return Spinner(60);
  } else {
    const { marginLeft, marginRight } = getGraphMarginSpeedChart(
      timeframe,
      screenWidth,
      data.length
    );

    const filteredRecords = normalizeApolloAllChartsRecords(
      data,
      maxKeepAlive,
      selectedSystemGroup
    );
    return (
      <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
        <BarChart
          margin={{ top: 17, left: marginLeft, right: marginRight }}
          data-automationid="overview-Total-impressions-by-hour"
          barSize={barSize}
          barGap={-barSize}
          data={filteredRecords}>
          //@ts-ignore
          <Tooltip
            cursor={{ fill: 'transparent' }}
            content={
              <CustomTooltip
                timeframe={timeframe}
                selectedSystemGroup={selectedSystemGroup}
                tooltipText={tooltipText}
                units={units}
                chartName={chartName}
              />
            }
          />
          <XAxis
            height={50}
            interval={0}
            axisLine={false}
            tickLine={{ stroke: '#C5D0DE' }}
            padding={{ left: 40, right: 40 }}
            tick={
              <AxixTiksForCustomTimeframe
                chart="speedChart"
                timeframe={timeframe}
                data={data}
                marginLeft={marginLeft}
                marginRight={marginRight}
                customTimeframeProperties={customTimeframeProperties}
              />
            }>
            <Label
              content={
                <CustomizedLabelGrid
                  timeframe={timeframe}
                  offsetLeft={marginLeft}
                  offsetRight={marginRight}
                  chartHeight={CHART_HEIGHT}
                />
              }
            />
          </XAxis>
          <Bar
            isAnimationActive={false}
            dataKey="totalAvg"
            fill={globalColors.regular.background.cardBorder}
            radius={[barRadius, barRadius, 0, 0]}
          />
          <Bar
            isAnimationActive={false}
            dataKey="total"
            fill={globalColors.regular.background.blue}
            radius={[barRadius, barRadius, 0, 0]}>
            <LabelList
              content={
                <ChartLabel
                  units={units}
                  selectedSystemGroup={selectedSystemGroup}
                  timeframe={timeframe}
                  columnWidth={barSize}></ChartLabel>
              }
              dataKey="total"
              position="top"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }
};
export default DesktopSpeedChart;
