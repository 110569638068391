import {
  ChartRecord,
  GranularityEnum,
  IsEverReportedSystemType,
  NickNameRecord,
} from './../store/storeTypes';
import { parseDate } from './chartUtils';
const isSistemHasNickName = (system, nickNamesArr: Array<NickNameRecord>): boolean => {
  const currentNickNameRecord = nickNamesArr.find(
    (record: NickNameRecord) => record.serialNumber === system.serialNumber
  );
  if (!currentNickNameRecord) {
    return false;
  }
  return currentNickNameRecord.nickName === null || system.systemNickName === '' ? false : true;
};
export const sortDataByOrderDesc = (
  dataArray,
  sortBy,
  order,
  nickNamesArr?: Array<NickNameRecord>,
  isEverReportedSystems?: Array<IsEverReportedSystemType>
) => {
  if (!dataArray) {
    return;
  }
  //*if sortBy=== serialNumber there is special buisness logic which tell us to separate array on two parts -with nickName and without and does sort inside this parts. part with nickName will be on top always
  if (sortBy === 'serialNumber') {
    const systemsWithNickName = new Array();
    const systemsWithoutNickName = new Array();
    dataArray.forEach(system => {
      if (!isSistemHasNickName(system, nickNamesArr)) {
        systemsWithoutNickName.push(system);
      } else {
        systemsWithNickName.push(system);
      }
    });
    const resultWithNickName = [...systemsWithNickName].sort((a, b) =>
      order === 'desc'
        ? descendingComparator(a, b, 'systemNickName', nickNamesArr, isEverReportedSystems)
        : -descendingComparator(a, b, 'systemNickName', nickNamesArr, isEverReportedSystems)
    );
    const resultWithoutNickName = [...systemsWithoutNickName].sort((a, b) =>
      order === 'desc'
        ? descendingComparator(a, b, sortBy, undefined, isEverReportedSystems)
        : -descendingComparator(a, b, sortBy, undefined, isEverReportedSystems)
    );
    return [...resultWithNickName, ...resultWithoutNickName];
  }
  if (sortBy === 'status') {
    const result = [...dataArray].sort((a, b) =>
      order === 'desc'
        ? descendingComparator(a, b, sortBy, undefined, isEverReportedSystems)
        : -descendingComparator(a, b, sortBy, undefined, isEverReportedSystems)
    );
    return result;
  } else {
    const result = [...dataArray].sort((a, b) =>
      order === 'desc'
        ? descendingComparator(a, b, sortBy, undefined, isEverReportedSystems)
        : -descendingComparator(a, b, sortBy, undefined, isEverReportedSystems)
    );
    return result;
  }
};

const descendingComparator = (
  a,
  b,
  sortBy: string,
  nickNamesArr?: Array<NickNameRecord>,
  isEverReportedSystems?: Array<IsEverReportedSystemType>
) => {
  //*special sorting for sortBy systemModel - we have model name as a first word in systemName field. we need to get if end equal it.
  if (sortBy === 'status') {
    const firstPrinterStatus = a.printerStatus || a;
    const secondPrinterStatus = b.printerStatus || b;
    if (!firstPrinterStatus || !secondPrinterStatus) {
      return 0;
    }
    const firstPrinterWeight = statusWeights[firstPrinterStatus];
    const secondPrinterWeight = statusWeights[secondPrinterStatus];
    if (secondPrinterWeight < firstPrinterWeight) {
      return -1;
    }
    if (secondPrinterWeight > firstPrinterWeight) {
      return 1;
    }
    return 0;
  }
  if (sortBy === 'systemModel') {
    const aSystemName = a.systemName;
    const bSystemName = b.systemName;
    //*in production there is no possibility to get  a.systemName === undefined or  b.systemName=== undefined but in dev it occures thats why there is checks below;
    if (!aSystemName || !bSystemName) {
      return 0;
    }

    const [aModel] = aSystemName.split(' ');
    const [bModel] = bSystemName.split(' ');

    if (bModel < aModel) {
      return -1;
    }
    if (bModel > aModel) {
      return 1;
    }
    return 0;
  }
  if (sortBy === 'systemNickName') {
    const nickNameRecord1 = nickNamesArr.find(
      (record: NickNameRecord) => record.serialNumber === a.serialNumber
    );
    const nickNameRecord2 = nickNamesArr.find(
      (record: NickNameRecord) => record.serialNumber === b.serialNumber
    );
    const name1 =
      nickNameRecord1 && nickNameRecord1.nickName && nickNameRecord1.nickName.toLowerCase();
    const name2 =
      nickNameRecord2 && nickNameRecord2.nickName && nickNameRecord2.nickName.toLowerCase();

    if (name2 < name1) {
      return -1;
    }
    if (name2 > name1) {
      return 1;
    }
    if (name2 === undefined && name1) {
      return -1;
    }
    if (name1 === undefined && name2) {
      return 1;
    }
    return 0;
  } else {
    //*the usual and most used case of equality
    //*isEverReported status could be undefined
    const isAEverReported = isEverReportedSystems?.find(
      system => system?.serialNumber === a.serialNumber
    )?.isEverReported;

    const isBEverReported = isEverReportedSystems?.find(
      system => system?.serialNumber === b.serialNumber
    )?.isEverReported;
    if (isAEverReported && !isBEverReported) {
      return -1;
    }
    if (isBEverReported && !isAEverReported) {
      return 1;
    }
    if (b[sortBy] < a[sortBy]) {
      return -1;
    }
    if (b[sortBy] > a[sortBy]) {
      return 1;
    }
    if (b[sortBy] === undefined && a[sortBy]) {
      return -1;
    }
    if (a[sortBy] === undefined && b[sortBy]) {
      return 1;
    }
    //*to move down all never reported systems =>

    return 0;
  }
};
export const getSortByArray = (isToday: boolean): Array<sortByArrayUnit> => {
  if (isToday) {
    return [
      { label: 'Status', value: '1', fieldName: 'status' },
      { label: 'System Type', value: '2', fieldName: 'systemModel' },
      { label: 'System Name', value: '3', fieldName: 'serialNumber' },
      { label: 'Production', value: '4', fieldName: 'production' },
      { label: 'Avg. Prod. Rate', value: '5', fieldName: 'speed' },
      { label: 'Availability', value: '6', fieldName: 'availability' },
      {
        label: 'Last update',
        value: '7',
        fieldName: 'lastUpdate',
      },
    ];
  } else {
    return [
      { label: 'System Type', value: '1', fieldName: 'systemModel' },
      { label: 'System Name', value: '2', fieldName: 'serialNumber' },
      { label: 'Production', value: '3', fieldName: 'production' },
      { label: 'Avg. Prod. Rate', value: '4', fieldName: 'speed' },
      { label: 'Availability', value: '5', fieldName: 'availability' },
    ];
  }
};
export type sortByArrayUnit = {
  label:
    | 'Status'
    | 'System Type'
    | 'System Name'
    | 'Production'
    | 'Avg. Prod. Rate'
    | 'Availability'
    | 'Last update';
  value: '1' | '2' | '3' | '4' | '5' | '6' | '7';
  fieldName:
    | 'status'
    | 'systemModel'
    | 'serialNumber'
    | 'production'
    | 'speed'
    | 'availability'
    | 'lastUpdate';
};
export const statusWeights = {
  Printing: 1,
  Preparation: 2,
  Idle: 3,
  NotReporting: 4,
  ['Not Reporting']: 4, // in some cases from server comes Not Reporting in some cases NotReporting
  Maintenance: 5,
  Maintenace: 5,
  Error: 6,
  NotDefined: 7,
};

export const sortLegendItemByStatus = (
  array: Array<{ statusPercentage: number; status: string }>
) => {
  return array.sort((a, b) => statusWeights[a.status] - statusWeights[b.status]);
};

type SortByGranularityArgsType = {
  granularity: GranularityEnum;
  records: Array<ChartRecord>;
};
export const sortByGranularity = ({ granularity, records }: SortByGranularityArgsType) => {
  //* this function is needed to sort records by date field of record. date field format depends on granularity.
  //* date field is string which could have a different formats depending on granularity and it is needed to be parsed to Date object for comparing.
  //* granularity Hour has date field in format        -       17:00 Wednesday 31 January  2024
  //* granularity Day  has date field in format        -            Tuesday 6 February 2024
  //* granularity Week  has date field in format       -       December 18 - December 24 , 2023
  //* granularity Month  has date field in format      -                202301

  function customDateSort(a, b): number {
    const dateA = parseDate(a.date, granularity);
    const dateB = parseDate(b.date, granularity);
    return dateA - dateB;
  }
  records.sort(customDateSort);
  return records;
};
