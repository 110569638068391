import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import find from 'lodash/find';

import { AuthSupportProps } from 'contracts/auth';
import {
  goalsPath,
  userManagementPath,
  METRICS_GLOSSARY_DTF_PDF_LINK,
  METRICS_GLOSSARY_DTG_PDF_LINK,
  KONNECT_TRAINING_LINK_DTG,
  KONNECT_TRAINING_LINK_DTF,
  settingsPath,
  apiManagementPath,
  schedulerPath,
  privacyPolicyPath,
  productTCPath,
  termsOfUsePath,
} from 'contracts/constants';
import PopupMenu from '../_common/PopupMenu';
import { withAuth } from 'services/AuthProvider';
// import { LOCALES } from 'services/i18n';
// import FlagWithText from '../_common/FlagWithText';
import { PermissionAuth0 } from 'models/Permissions';
import { IUserSettingsState, Store, UserSystemGroup } from 'store/storeTypes';
import { appUserToRoles } from 'selectors/uiSelectors';
import { PopupMenuItemValueType } from 'components/_common/PopupMenuItem';
import { openLinkInNewTab } from 'helpers/navigation';

interface ShellUserNavProps extends AuthSupportProps {
  children: React.ReactNode;
  appUserRoles: PermissionAuth0[];
  userSettings: IUserSettingsState;
  selectedSystemGroup: string;
  isReadOnlyRole: boolean;
}

const mapStateToProps = (state: Store) => ({
  appUserRoles: appUserToRoles(state),
  userSettings: state.userSettings,
});

const ShellUserNav = (props: ShellUserNavProps) => {
  const {
    authService,
    children,
    appUserRoles,
    userSettings,
    selectedSystemGroup,
    isReadOnlyRole,
  } = props;
  const KONNECT_TRAINING_LINK =
    selectedSystemGroup === 'DTG' ? KONNECT_TRAINING_LINK_DTG : KONNECT_TRAINING_LINK_DTF;
  const { t } = useTranslation();

  // const currentLocale = find(LOCALES, l => l.i18n === i18n.language) || LOCALES[0];

  const navLinks: PopupMenuItemValueType[] = [];

  !appUserRoles.includes(PermissionAuth0.ReadOnly) &&
    navLinks.push({
      text: t('userNav.scheduler'),
      path: schedulerPath,
    });

  if (appUserRoles) {
    if (!appUserRoles.includes(PermissionAuth0.ReadOnly)) {
      appUserRoles.includes(PermissionAuth0.GoalsSetter) &&
        navLinks.push({
          text: t('userNav.goals'),
          path: goalsPath,
        });
      appUserRoles.includes(PermissionAuth0.Certificate) &&
        navLinks.push({
          text: t('userNav.apiManagement'),
          path: apiManagementPath,
        });

      appUserRoles.includes(PermissionAuth0.Admin) &&
        navLinks.push({
          text: t('userNav.accountManagement'),
          path: userManagementPath,
        });
      navLinks.push('divider');
    }
  }

  const helpChildren = [
    {
      text: t('userNav.help.training'),
      onClick: () => openLinkInNewTab(KONNECT_TRAINING_LINK),
    },
  ];

  if (userSettings.data?.userSystemGroup?.includes(UserSystemGroup.DTF)) {
    helpChildren.push({
      text: t('userNav.help.metricsGlossary') + ' ' + UserSystemGroup.DTF,
      onClick: () => openLinkInNewTab(METRICS_GLOSSARY_DTF_PDF_LINK),
    });
  }

  if (userSettings.data?.userSystemGroup?.includes(UserSystemGroup.DTG)) {
    helpChildren.push({
      text: t('userNav.help.metricsGlossary') + ' ' + UserSystemGroup.DTG,
      onClick: () => openLinkInNewTab(METRICS_GLOSSARY_DTG_PDF_LINK),
    });
  }

  navLinks.push(
    {
      text: t('userNav.help.helpLabel'),
      children: helpChildren,
    },
    {
      text: t('userNav.terms.termsAndCond'),
      children: [
        {
          text: t('userNav.terms.productTC'),
          path: productTCPath,
        },
        {
          text: t('userNav.terms.privacyPolicy'),
          path: privacyPolicyPath,
        },
        {
          text: t('userNav.terms.termsOfUse'),
          path: termsOfUsePath,
        },
      ],
    },
    'divider',
    {
      text: t('userNav.logout'),
      onClick: authService.logout,
    }
  );
  if (isReadOnlyRole) {
    navLinks.push({
      text: 'Switch Customer',
      onClick: () => window.location.reload(),
    });
  }

  // If userSystemGroup has ['DTF'] or ['DTF', 'DTG'] AND if it is not readOnly user show settings menu item
  if (
    userSettings.data.userSystemGroup?.length === 2 ||
    userSettings.data.userSystemGroup?.includes(UserSystemGroup.DTF)
  ) {
    if (!appUserRoles.includes(PermissionAuth0.ReadOnly)) {
      navLinks.splice(navLinks.length - 1, 0, {
        text: t('userNav.settings'),
        path: settingsPath,
      });
    }
  }
  return <PopupMenu menuItems={navLinks}>{children}</PopupMenu>;
};

export default connect(mapStateToProps)(withAuth(ShellUserNav));
