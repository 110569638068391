import {
  differenceInDays,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  isSameDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { globalColors } from 'globalStyle';
import { transformTime } from 'helpers/conversions';
import { TimeFrameEnum } from 'models/ReportData';
import React from 'react';
import { GranularityEnum } from 'store/storeTypes';

const AxixTiksForCustomTimeframe = props => {
  const {
    x,
    y,
    data,
    width,
    index,
    marginLeft,
    marginRight,
    chart,
    timeframe,
    customTimeframeProperties,
  } = props;
  const LAST_HOUR = 23;
  const MONTH_NAME_LENGTH = 17;
  let isAdditionalLabelHasTwoDates = false;
  const record = data[index];
  const granularity = data[0]?.granularity;
  const ADDITIONAL_MONTH_LABEL_Y_OFFSET = 28;
  const ADDITIONAL_WEEK_Y_OFFSET = 39;
  const ADDITIONAL_DAYS_LABEL_Y_OFFSET = 36;
  const getAdditionalData = (data, granularity): string => {
    if (granularity === GranularityEnum.Hour) {
      const [
        firstHour,
        firstDayName,
        firstDayNumber,
        firstMonthName,
        firstYear,
      ] = data[0].date.split(' ');
      const [lastHour, lastDayName, lastDayNumber, lastMonthName, lastYear] = data[
        data.length - 1
      ].date.split(' ');
      if (lastMonthName === firstMonthName) {
        isAdditionalLabelHasTwoDates = false;
        return `${firstDayNumber} ${firstDayName} ${firstYear}`;
      } else {
        isAdditionalLabelHasTwoDates = true;
        return `${firstDayNumber} ${firstDayName} ${firstYear} | ${lastDayNumber} ${lastDayName} ${lastYear}`;
      }
    }
    if (granularity === GranularityEnum.Day) {
      const [firstDayName, firstDayNumber, firstMonthName, firstYear] = data[0].date.split(' ');
      const [lastDayName, lastDayNumber, lastMonthName, lastYear] = data[
        data.length - 1
      ].date.split(' ');
      if (firstMonthName === lastMonthName) {
        isAdditionalLabelHasTwoDates = false;
        return `${firstMonthName.slice(0, 3)} ${firstYear}`;
      } else {
        isAdditionalLabelHasTwoDates = true;

        return `${firstMonthName.slice(0, 3)} ${firstYear} - ${lastMonthName.slice(
          0,
          3
        )} ${lastYear} `;
      }
    }
    if (granularity === GranularityEnum.Week) {
      const firstYear = data[0].date.split(',')[1].trim();
      const lastYear = data[data.length - 1].date.split(',')[1].trim();
      if (firstYear === lastYear) {
        isAdditionalLabelHasTwoDates = false;
        return `${firstYear}`;
      } else {
        isAdditionalLabelHasTwoDates = true;
        return `${firstYear} - ${lastYear}`;
      }
    }
    if (granularity === GranularityEnum.Month) {
      const firstYear = data[0].date.slice(0, 4);
      const lastYear = data[data.length - 1].date.slice(0, 4);
      if (firstYear === lastYear) {
        isAdditionalLabelHasTwoDates = false;
        return `${firstYear}`;
      } else {
        isAdditionalLabelHasTwoDates = true;
        return `${firstYear} - ${lastYear}`;
      }
    }
  };
  if (granularity === GranularityEnum.Hour) {
    const [hourStr, dayNameStr, dayNumberStr, monthNameStr, year] = record.date.split(' ');
    const [hour, timeModificator] = transformTime(hourStr).split(' ');
    const xOffset = hour.length < 2 ? x - 3 : x - 6;
    const additionalOffsetForTimeModificator = hour.length < 2 ? -3 : -1;

    const additionalDataLabel = getAdditionalData(data, GranularityEnum.Day);
    const additionalLabelColor = isAdditionalLabelHasTwoDates ? '#FFAB2B' : '#7F8FA4';
    let xOffsetForAdditionalLabel = width / 2 - 15 + marginLeft;
    const firstRecordDayName = data[0].date.split(' ')[1];
    const indexOfSecondDay = data.findIndex(record => {
      return record.date.split(' ')[1] !== firstRecordDayName;
    });

    return (
      <text x={xOffset} y={props.y + 10} width={10} height={50}>
        <tspan fill={globalColors.regular.text.grey} fontSize={12}>
          {hour}
        </tspan>
        <tspan
          fill={globalColors.regular.text.grey}
          fontSize={10}
          x={xOffset + additionalOffsetForTimeModificator}
          dy={12}>
          {timeModificator}
        </tspan>
        {index === 0 && !isAdditionalLabelHasTwoDates && (
          <tspan
            textAnchor="middle"
            fill={additionalLabelColor}
            fontSize={10}
            dy={15}
            x={
              chart !== 'totalProduction'
                ? xOffsetForAdditionalLabel
                : xOffsetForAdditionalLabel + 4
            }>
            {additionalDataLabel}
          </tspan>
        )}
        {index === indexOfSecondDay && isAdditionalLabelHasTwoDates && (
          <tspan
            textAnchor="middle"
            fill={additionalLabelColor}
            fontSize={10}
            dy={15}
            x={xOffset + 8}>
            {additionalDataLabel}
          </tspan>
        )}
      </text>
    );
  }
  if (granularity === GranularityEnum.Day) {
    const monthNameLength = 40;
    const [dayName, dayNumber] = record.date.split(' ');
    const xOffset = dayNumber.length < 2 ? x - 3 : x - 5;
    const additionalDataLabel = getAdditionalData(data, granularity);
    const additionalLabelColor = isAdditionalLabelHasTwoDates ? '#FFAB2B' : '#7F8FA4';
    const xOffsetForAdditionalLabel = width / 2 - 15 + marginLeft;

    const startDate = customTimeframeProperties?.customTimeFrameStartDate;
    const endDate = customTimeframeProperties?.customTimeFrameEndDate;
    const startDateStartHour = customTimeframeProperties?.customTimeFrameStartHour;
    const endDateEndHour = customTimeframeProperties?.customTimeFrameEndHour;

    const selectedHoursCountStartDate = 24 - startDateStartHour;
    const selectedHoursCountEndDate = endDateEndHour;
    const firstMonthName = data[0].date.split(' ')[2];
    const indexOfSecondMonth = data.findIndex(record => {
      return record.date.split(' ')[2] !== firstMonthName;
    });
    return (
      <text x={xOffset} y={props.y + 10} width={10} height={50}>
        <tspan fill={globalColors.regular.text.grey} fontSize={12}>
          {dayNumber}
        </tspan>
        <tspan
          fill={globalColors.regular.text.grey}
          fontSize={10}
          x={dayNumber.length > 1 ? xOffset : xOffset - 4}
          dy={12}>
          {dayName.slice(0, 3)}
        </tspan>
        {index === 0 && !isAdditionalLabelHasTwoDates && (
          <tspan
            fill={additionalLabelColor}
            fontSize={10}
            x={
              chart !== 'totalProduction'
                ? xOffsetForAdditionalLabel
                : xOffsetForAdditionalLabel + MONTH_NAME_LENGTH - marginLeft / 2
            }
            dy={15}>
            {additionalDataLabel}
          </tspan>
        )}
        {index === indexOfSecondMonth &&
          isAdditionalLabelHasTwoDates &&
          timeframe === TimeFrameEnum.custom && (
            <tspan
              fill={additionalLabelColor}
              fontSize={10}
              x={xOffset - monthNameLength}
              y={y + ADDITIONAL_DAYS_LABEL_Y_OFFSET}>
              {additionalDataLabel}
            </tspan>
          )}
        {index === 0 && selectedHoursCountStartDate !== 24 && (
          <tspan
            fontSize={10}
            fill={'#FFAB2B'}
            y={props.y + ADDITIONAL_DAYS_LABEL_Y_OFFSET}
            x={dayNumber.length > 1 ? xOffset - 10 : xOffset - 14}>
            {selectedHoursCountStartDate} hours
          </tspan>
        )}
        {index === data.length - 1 &&
          selectedHoursCountEndDate < LAST_HOUR &&
          timeframe === TimeFrameEnum.custom && (
            <tspan
              fontSize={10}
              fill={'#FFAB2B'}
              y={props.y + ADDITIONAL_DAYS_LABEL_Y_OFFSET}
              x={dayNumber.length > 1 ? xOffset - 10 : xOffset - 14}>
              {selectedHoursCountEndDate + 1} hours
            </tspan>
          )}
      </text>
    );
  }
  if (granularity === GranularityEnum.Week) {
    const [weekRange, year] = record.date.split(',');
    const [startWeekStr, endWeekStr] = weekRange.split('-');
    const startWeekDay = startWeekStr.trim().slice(-2);
    const endWeekDay = endWeekStr.trim().slice(-2);
    const startWeekMonth = startWeekStr.trim().slice(0, 3);
    const endWeekMonth = endWeekStr.trim().slice(0, 3);
    const xOffset = x - 4;

    const additionalDataLabel = getAdditionalData(data, granularity);
    const additionalLabelColor = isAdditionalLabelHasTwoDates ? '#FFAB2B' : '#7F8FA4';
    let xOffsetForAdditionalLabel = width / 2 - 15 + marginLeft;

    let secondRowXOffset = xOffset - 17;
    //next 2 if conditions are for centering additional label
    //if startWeek day number contains 2 digits and end week day number contains 2 digit
    if (startWeekDay.trim().length > 1 && endWeekDay.trim().length > 1) {
      secondRowXOffset = xOffset - 15;
    }
    //if startWeek day number contains 1 digit and end week day number contains 2 digit
    if (startWeekDay.trim().length === 1 && endWeekDay.trim().length > 1) {
      secondRowXOffset = xOffset - 18;
    }
    //if startWeek day number contains 1 digit and end week day number contains 1 digit
    if (startWeekDay.trim().length === 1 && endWeekDay.trim().length === 1) {
      secondRowXOffset = xOffset - 18;
    }
    //if startWeek day number contains 2 digit and end week day number contains 1 digit
    if (startWeekDay.trim().length > 1 && endWeekDay.trim().length === 1) {
      secondRowXOffset = xOffset - 15;
    }

    //if week not full we need to show special label
    const startDate = customTimeframeProperties?.customTimeFrameStartDate;
    const endDate = customTimeframeProperties?.customTimeFrameEndDate;
    const isFirstWeekFull = isSameDay(startDate, startOfWeek(startDate, { weekStartsOn: 1 }));
    const isEndWeekFull = isSameDay(endDate, endOfWeek(endDate, { weekStartsOn: 1 }));
    const selectedDaysConuntInFirstWeek =
      differenceInDays(endOfWeek(startDate, { weekStartsOn: 1 }), startDate) + 1;
    const selectedDaysCountInEndWeek =
      differenceInDays(endDate, startOfWeek(endDate, { weekStartsOn: 1 })) + 1;
    const firstYearname = data[0].date.split(',')[1].trim();
    const indexOfSecondYear = data.findIndex(record => {
      return record.date.split(',')[1].trim() !== firstYearname;
    });
    return (
      <text x={xOffset - 15} y={props.y + 10} width={10} height={50}>
        <tspan fill={globalColors.regular.text.grey} fontSize={12}>
          {startWeekDay} {startWeekMonth}
        </tspan>

        <tspan fill={globalColors.regular.text.grey} fontSize={12} x={secondRowXOffset} dy={12}>
          {endWeekDay} {endWeekMonth}
        </tspan>
        {!isFirstWeekFull && index === 0 && (
          <tspan
            x={xOffset - 15}
            y={props.y + ADDITIONAL_WEEK_Y_OFFSET}
            fill={'#FFAB2B'}
            fontSize={10}>
            {selectedDaysConuntInFirstWeek} days
          </tspan>
        )}
        {!isEndWeekFull && index === data.length - 1 && (
          <tspan
            x={secondRowXOffset}
            y={props.y + ADDITIONAL_WEEK_Y_OFFSET}
            fill={'#FFAB2B'}
            fontSize={10}>
            {selectedDaysCountInEndWeek} days
          </tspan>
        )}
        {index === 0 && !isAdditionalLabelHasTwoDates && (
          <tspan
            fill={additionalLabelColor}
            fontSize={10}
            x={xOffsetForAdditionalLabel}
            y={y + ADDITIONAL_WEEK_Y_OFFSET}>
            {additionalDataLabel}
          </tspan>
        )}
        {index === indexOfSecondYear &&
          isAdditionalLabelHasTwoDates &&
          index !== 0 &&
          index !== data.length - 1 && (
            <tspan
              fill={additionalLabelColor}
              fontSize={10}
              x={xOffset - 25}
              y={y + ADDITIONAL_WEEK_Y_OFFSET}>
              {additionalDataLabel}
            </tspan>
          )}
      </text>
    );
  }
  if (granularity === GranularityEnum.Month) {
    //*we have to substruct 1 from month number beacuse in JS monthes starts from 0 and on back end side starts from 1;
    const monthNumber = +record.date.slice(-2) - 1;
    //here it is no matter which year to use the main purpose to create new date with propper month
    const date = new Date(2022, monthNumber);
    const monthName = date.toLocaleString('default', { month: 'short' });
    const additionalDataLabel = getAdditionalData(data, granularity);
    const additionalLabelColor = isAdditionalLabelHasTwoDates ? '#FFAB2B' : '#7F8FA4';
    const xOffset = x - 5;
    //leftBorderLabel and rightBorderLabel are used to show user that first and last record is not full month
    const startDate = customTimeframeProperties?.customTimeFrameStartDate;
    const endDate = customTimeframeProperties?.customTimeFrameEndDate;
    const isFirstMonthFull = isSameDay(startDate, startOfMonth(startDate));
    const isEndMonthFull = isSameDay(endDate, endOfMonth(endDate));
    const selectedDaysConuntInFirstMonth = differenceInDays(endOfMonth(startDate), startDate) + 1;
    const selectedDaysCountInEndMonth = differenceInDays(endDate, startOfMonth(endDate)) + 1;
    const firstYearname = data[0].date.slice(0, 4);
    const indexOfSecondYear = data.findIndex(record => {
      return record.date.slice(0, 4) !== firstYearname;
    });
    let xOffsetForAdditionalLabel = width / 2 - 15 + marginLeft;

    return (
      <text x={xOffset - 9} y={props.y + 10} width={10} height={50}>
        <tspan fill={globalColors.regular.text.grey} fontSize={12}>
          {monthName}
        </tspan>
        {!isFirstMonthFull && index === 0 && (
          <tspan
            fontSize={10}
            fill={'#FFAB2B'}
            x={xOffset - 15}
            y={props.y + ADDITIONAL_MONTH_LABEL_Y_OFFSET}>
            {selectedDaysConuntInFirstMonth} days
          </tspan>
        )}

        {!isEndMonthFull && index === data.length - 1 && (
          <tspan
            fontSize={10}
            fill={'#FFAB2B'}
            x={xOffset - 15}
            y={props.y + ADDITIONAL_MONTH_LABEL_Y_OFFSET}>
            {selectedDaysCountInEndMonth} days
          </tspan>
        )}
        {index === 0 && !isAdditionalLabelHasTwoDates && (
          <tspan fill={additionalLabelColor} fontSize={10} x={xOffsetForAdditionalLabel} dy={12}>
            {additionalDataLabel}
          </tspan>
        )}

        {index === indexOfSecondYear &&
          isAdditionalLabelHasTwoDates &&
          index !== 0 &&
          index !== data.length - 1 && (
            <tspan
              fill={additionalLabelColor}
              fontSize={10}
              x={xOffset - 20}
              y={y + ADDITIONAL_MONTH_LABEL_Y_OFFSET}>
              {additionalDataLabel}
            </tspan>
          )}
      </text>
    );
  } else {
    return (
      <text x={props.x} y={props.y + 10} width={10} height={10}>
        <tspan>{props.payload.value}</tspan>
      </text>
    );
  }
};
export default AxixTiksForCustomTimeframe;
